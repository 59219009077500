













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { latLng } from 'leaflet';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import "leaflet/dist/leaflet.css"; // needs to be imported in order for leaflet to work correctly

@Component({
  components: {
    LMap: LMap,
    LTileLayer: LTileLayer,
    LMarker: LMarker
  }
})
export default class MapComponent extends Vue {
  @Prop()
  public coordinates?: { lat: number, lng: number };

  private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

  /**
   * Returns coordinates as Leaflet latLng.
   * If coordinates are undefined, coordinates of the center of Germany are returned.
   */
  get mapCoordinates() {
    if (this.coordinates) {
      return latLng(this.coordinates.lat, this.coordinates.lng);
    } else {
      return latLng(52.510494, 13.396764);
    }
  }

  private markerIcon = L.divIcon({
    html: '<i class="fas fa-map-marker-alt fa-4x"></i>',
    iconSize: [15, 15],
    className: 'marker-icon'
  });

}
